
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
	font-family: "Google Sans",sans-serif !important;
}



:root{
  --primary-bg-color: #12161f;
  --secondary-bg-color:  #282c34;
  --text-color: #e9ebf0;
  --secondary-text-color: #9e9ea0;
  --hint-color: var(--tg-theme-hint-color);
  --link-color: var(--tg-theme-link-color);
  --button-color: var(--tg-theme-button-color);
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.4);
  --rsbs-bg: var(--primary-bg-color);
  --rsbs-handle-bg: #6e6e6e;
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 24px;

}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--primary-bg-color) !important;
}

[data-rsbs-header]{
  height: 50px !important;
}
[data-rsbs-header]:before{
  top: calc(14px + env(safe-area-inset-top))
}
[data-rsbs-footer]{
  background: var(--primary-bg-color);
  box-shadow: 0px 4px 4px 4px var(--secondary-bg-color);
  border-radius: 20px 20px 0px 0px;
}

.main_container {
  background: var(--primary-bg-color);
  text-align: center;
  width: 100%;
  min-width: 360px;
}

.head {
  color: var(--text-color);
  background: var(--secondary-bg-color);
  width: 100%;
  padding: 8px 8px 8px 8px;
  border-radius: 0px 0px 20px 20px;
  height: 64px;
  display: flex;
  text-align: left;
  font-size: 18px;
}

.eh-balance{
  display: block;
  width: 50%;
  text-align: right;
  font-size: 20px;

}

.header {
  padding: 12px;
  text-align: left;
  font-size: 16px;
  color: var(--text-color);
  display: flex;
  align-items: center;
}





