.swiper {
  width: 100%;
  height: 100%;
}

.mySwiper .swiper-slide {
  text-align: left;
  font-size: 18px;
  width: 340px;
  height: 100px;
  background: var(--secondary-bg-color);
  border-radius: 20px;
}

.sw1-card-header{
  display: flex;
  justify-content: 'center';
  align-items: 'center';
}