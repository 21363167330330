.swiper {
  width: 100%;
  height: 100%;
}

.main-mySwiper .swiper-slide {
  display: flex;
  align-items: bottom;
  justify-content: center;
  text-align: left;
  font-size: 18px;
  width: 180px;
  height: 246px;
  transition: transform 0.15s;
}

.swiper-slide:active {
  transform: scale(0.96);
}

.main-sw1-card-header{
  font-size: 16px;
  color: var(--text-color);
}

.drawer-slide {
  display: flex;
  align-items: bottom;
  justify-content: center;
  text-align: left;
  font-size: 18px;
  width: 180px;
  height: 246px;
  margin: 4px;
  transition: transform 0.15s;
}

.drawer-slide:active {
  transform: scale(0.96);
}

.slide-product-container{
  background: var(--secondary-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  padding: 12px;
  border-radius: 32px;
}