.onefour-big{
  height: 140px;
  padding: 12px;
  border-radius: 20px;
  background: var(--secondary-bg-color);
}

.onefour-sm-row{
  height: 67px;
  display: flex;
  justify-content: space-between;
}

.onefour-sm{
  padding: 4px;
  background: var(--secondary-bg-color);
  border-radius: 20px;
  width: 49%;
  transition: transform 0.15s;
}

.onefour-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.onefour-sm:active {
  transform: scale(0.96);
}